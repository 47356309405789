import React from 'react';
import Footer from 'gatsby-theme-carbon/src/components/Footer';

const Content = () => (
  <>
    <p>
      This guidance remains a work in progress.<br />
      If you feel it can be improved, extended or clarified, please contact us.
    </p>
  </>
);

const links = {
  firstCol: [
    { href: 'https://construct.kope.ai', linkText: 'KOPE Construct' },
    { href: 'https://offsite.market', linkText: 'KOPE Marketplace US' },
    { href: 'https://mmc.market', linkText: 'KOPE Marketplace UK' }
  ],
  secondCol: [
    { href: 'https://kope.ai', linkText: 'KOPE website' }
  ]
};

const CustomFooter = () => <Footer links={links} Content={Content} />;

export default CustomFooter;
