// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-getting-started-configurator-mdx": () => import("./../../../src/pages/getting-started/configurator.mdx" /* webpackChunkName: "component---src-pages-getting-started-configurator-mdx" */),
  "component---src-pages-getting-started-index-mdx": () => import("./../../../src/pages/getting-started/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-index-mdx" */),
  "component---src-pages-getting-started-outputs-mdx": () => import("./../../../src/pages/getting-started/outputs.mdx" /* webpackChunkName: "component---src-pages-getting-started-outputs-mdx" */),
  "component---src-pages-getting-started-products-mdx": () => import("./../../../src/pages/getting-started/products.mdx" /* webpackChunkName: "component---src-pages-getting-started-products-mdx" */),
  "component---src-pages-getting-started-projects-mdx": () => import("./../../../src/pages/getting-started/projects.mdx" /* webpackChunkName: "component---src-pages-getting-started-projects-mdx" */),
  "component---src-pages-getting-started-register-for-kope-mdx": () => import("./../../../src/pages/getting-started/register-for-kope.mdx" /* webpackChunkName: "component---src-pages-getting-started-register-for-kope-mdx" */),
  "component---src-pages-getting-started-results-mdx": () => import("./../../../src/pages/getting-started/results.mdx" /* webpackChunkName: "component---src-pages-getting-started-results-mdx" */),
  "component---src-pages-getting-started-set-up-mdx": () => import("./../../../src/pages/getting-started/set-up.mdx" /* webpackChunkName: "component---src-pages-getting-started-set-up-mdx" */),
  "component---src-pages-getting-started-supply-chains-mdx": () => import("./../../../src/pages/getting-started/supply-chains.mdx" /* webpackChunkName: "component---src-pages-getting-started-supply-chains-mdx" */),
  "component---src-pages-help-contact-mdx": () => import("./../../../src/pages/help/contact.mdx" /* webpackChunkName: "component---src-pages-help-contact-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-kope-accessing-kope-mdx": () => import("./../../../src/pages/kope/accessing-kope.mdx" /* webpackChunkName: "component---src-pages-kope-accessing-kope-mdx" */),
  "component---src-pages-kope-case-studies-mdx": () => import("./../../../src/pages/kope/case-studies.mdx" /* webpackChunkName: "component---src-pages-kope-case-studies-mdx" */),
  "component---src-pages-kope-configurator-page-mdx": () => import("./../../../src/pages/kope/configuratorPage.mdx" /* webpackChunkName: "component---src-pages-kope-configurator-page-mdx" */),
  "component---src-pages-kope-index-mdx": () => import("./../../../src/pages/kope/index.mdx" /* webpackChunkName: "component---src-pages-kope-index-mdx" */),
  "component---src-pages-kope-organisation-settings-mdx": () => import("./../../../src/pages/kope/organisation-settings.mdx" /* webpackChunkName: "component---src-pages-kope-organisation-settings-mdx" */),
  "component---src-pages-kope-products-mdx": () => import("./../../../src/pages/kope/products.mdx" /* webpackChunkName: "component---src-pages-kope-products-mdx" */),
  "component---src-pages-kope-project-environment-mdx": () => import("./../../../src/pages/kope/project-environment.mdx" /* webpackChunkName: "component---src-pages-kope-project-environment-mdx" */),
  "component---src-pages-kope-projects-mdx": () => import("./../../../src/pages/kope/projects.mdx" /* webpackChunkName: "component---src-pages-kope-projects-mdx" */),
  "component---src-pages-kope-results-mdx": () => import("./../../../src/pages/kope/results.mdx" /* webpackChunkName: "component---src-pages-kope-results-mdx" */),
  "component---src-pages-kope-supply-chains-mdx": () => import("./../../../src/pages/kope/supply-chains.mdx" /* webpackChunkName: "component---src-pages-kope-supply-chains-mdx" */),
  "component---src-pages-welcome-index-mdx": () => import("./../../../src/pages/welcome/index.mdx" /* webpackChunkName: "component---src-pages-welcome-index-mdx" */)
}

